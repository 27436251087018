import React from 'react'
import styled from 'styled-components'
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'

export const LanguageSwitcher = () => {
  const { languages, originalPath } = useI18next()
  const { i18n } = useTranslation('home')

  return (
    <StyledSwitcher className="language-switcher">
      {languages.map((lang) => (
        <li key={lang} className={lang === i18n.language ? 'active' : ''}>
          <Link to={originalPath} language={lang}>
            {lang}
          </Link>
        </li>
      ))}
    </StyledSwitcher>
  )
}

export const StyledSwitcher = styled.ul`
  list-style-type: none;

  li {
    font-family: ${({ theme }) => theme.fontFamilySecondary};
    font-size: 1.7rem;
    line-height: 2rem;
    letter-spacing: 0.12rem;
    text-transform: uppercase;
    margin: ${({ theme }) =>
      `${theme.spacingXS} ${theme.spacingXS} ${theme.spacingXS} 0`};

    &:not(.active) a {
      color: transparent;
      -webkit-text-stroke: 1px ${({ theme }) => theme.white};
    }
  }
`
