import React, { useState } from 'react'
import { CTA } from './CTA'
import { LoadingSpinner } from './LoadingSpinner'
import ContactsIcon from '../assets/images/contacts.svg'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import axios from 'axios'

export const FormSection = ({ isDinastiaPage }) => {
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [choice, setChoice] = useState([])
  const [message, setMessage] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const [isSent, setIsSent] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [messageClass, setMessageClass] = useState('')

  const { t } = useTranslation()
  const content = t('form', { returnObjects: true })
  const msgMark = t('msgMark', { returnObjects: true })

  const handleChoiceChange = (val) => {
    if (choice.includes(val)) {
      setChoice(choice.filter((item) => item !== val))
    } else {
      setChoice([...choice, val])
    }
  }

  function handlePhone(url, type) {
    if (typeof window !== "undefined") {
      window.gtag('event', 'chamadas', {
        tipo_chamada: type,
      })
      window.open("tel:" + url, '_blank', 'noreferrer')
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true)

    const formData = new FormData()
    formData.append('name', name)
    formData.append('lastName', lastName)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('message', message)
    formData.append('choice', choice.join(', '))

    axios
      .post('https://next2u.pt/contact-form.php', formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data; charset=UTF-8',
        },
      })
      .then((res) => {
        if (res.data === 'success') {
          setMessageClass('contact-form-success')
          if (typeof window !== "undefined") {
            window.gtag('event', 'Enviado', {
              event_category: 'Formulário',
              event_label: 'Formulário Lead - Green'
            })
            window.gtag('event', 'form_contacto', {
              form: 'Formulário de Contacto',
              objetivo: choice.join(', ')
            })
          }
        } else {
          setHasError(true)
          setMessageClass('contact-form-error')
        }
      })
      .catch((err) => {
        setHasError(true)
        setMessageClass('contact-form-error')
      })
      .finally(() => {
        setIsLoading(false)
        setIsSent(true)
      })
  }

  return (
    <StyledSection className="form-section" id="form">
      <h1
        className="title"
        dangerouslySetInnerHTML={{
          __html: isDinastiaPage ? content.contactTitle : content.title,
        }}
      />
      {isLoading || hasError || isSent ? (
        <div className="feedback">
          {isLoading ? (
            <LoadingSpinner />
          ) : hasError ? (
            <p
              dangerouslySetInnerHTML={{ __html: content.errorMessage }}
              className={messageClass}
            />
          ) : (
            isSent && (
              <p
                dangerouslySetInnerHTML={{
                  __html: content.successMessage,
                }}
                className={messageClass}
              />
            )
          )}
        </div>
      ) : (
        <div className="content">
          <div className="contacts">
            <ContactsIcon />
            <a onClick={() => handlePhone(isDinastiaPage
              ? content.numberDinastia.replace(/\s/g, '')
              : content.number.replace(/\s/g, ''), 'Móvel')}
              rel="noopener"
              className="number"
            >
              {isDinastiaPage ? content.numberDinastia : content.number}
            </a>
            <br />
            <span style={{ fontStyle: "italic" }}>{msgMark.mobile}</span>
            <a
              href={`mailto: ${isDinastiaPage ? content.emailDinastia : content.email
                }`}
              rel="noopener"
              className="email"
            >
              {isDinastiaPage ? content.emailDinastia : content.email}
            </a>
          </div>

          <form onSubmit={handleSubmit}>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={content.nameLabel}
              required
            />
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder={content.lastNameLabel}
            />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={content.emailLabel}
              required
            />
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder={content.phoneLabel}
              required
            />

            {isDinastiaPage ? (
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder={content.messageLabel}
              />
            ) : (
              <>
                <div className="radio-buttons">
                  <span>{content.radioLabel}</span>
                  {content.radioOptions &&
                    content.radioOptions.length > 0 &&
                    content.radioOptions.map((option) => (
                      <label key={option}>
                        <input
                          type="checkbox"
                          value={option}
                          checked={choice.includes(option)}
                          onChange={(e) => handleChoiceChange(e.target.value)}
                        />
                        <span className="radio"></span>
                        {option}
                      </label>
                    ))}
                </div>

                <p
                  className={`info-message ${choice.includes(content.radioOptions[0]) ? 'show' : ''
                    }`}
                >
                  {content.infoMessage}
                </p>
              </>
            )}

            <div className="checkbox">
              <label>
                <input type="checkbox" required />
                <span className="check"></span>
                {content.termsLabel}
              </label>
            </div>
            <CTA text={content.send} underline />
          </form>
        </div>
      )}
    </StyledSection>
  )
}

export const StyledSection = styled.section`
  position: relative;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacingXL}`};

  a{
    cursor: pointer;
  }
  .title {
    text-align: center;
  }

  .feedback {
    width: auto;
    min-height: 50rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
      font-family: ${({ theme }) => theme.fontFamilySecondary};
      font-size: 2rem;
      line-height: 3.5rem;
      letter-spacing: 0.12rem;
      text-transform: uppercase;
      text-align: center;
      margin-top: ${({ theme }) => theme.spacingS};
    }

    .loading-spinner {
      margin-top: ${({ theme }) => theme.spacingS};
    }

    .close {
      position: unset;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.spacingM};
  }

  .contacts {
    font-family: ${({ theme }) => theme.fontFamilySecondary};

    svg {
      width: 17rem;
      height: auto;
    }

    .number {
      display: block;
      font-size: 2.7rem;
      letter-spacing: 0.4rem;
      margin-top: ${({ theme }) => theme.spacingS};
      transition: color 0.25s ease;

      &:hover {
        color: ${({ theme }) => theme.green};
      }
    }

    .email {
      display: block;
      font-size: 1.6rem;
      text-transform: uppercase;
      letter-spacing: 0.2rem;
      margin-top: ${({ theme }) => theme.spacingS};
      transition: color 0.25s ease;

      &:hover {
        color: ${({ theme }) => theme.purple};
      }
    }
  }

  form {
    input,
    textarea {
      width: 100%;
      color: ${({ theme }) => theme.white};
      font-size: 1.6rem;
      text-transform: uppercase;
      padding-bottom: 2rem;
      margin-top: 4rem;
      border-bottom: 1px solid ${({ theme }) => theme.white};

      ::placeholder {
        color: ${({ theme }) => theme.white};
      }
    }

    textarea {
      min-height: 10rem;
    }

    .radio-buttons {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      letter-spacing: 0.06rem;
      margin-top: 4rem;

      span {
        font-size: 1.5rem;
      }

      label {
        position: relative;
        font-size: 1.3rem;
        margin-left: ${({ theme }) => theme.spacingM};
        padding-left: ${({ theme }) => theme.spacingS};
        cursor: pointer;

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;

          &:checked ~ .radio {
            background: ${({ theme }) => theme.white};
          }
        }

        .radio {
          position: absolute;
          top: 0;
          left: 0;
          width: 1.6rem;
          height: 1.6rem;
          border: 1px solid ${({ theme }) => theme.white};
          border-radius: 50%;
        }
      }
    }

    .info-message {
      font-size: 1.3rem;
      margin-top: ${({ theme }) => theme.spacingS};
      visibility: hidden;
      text-transform: uppercase;

      &.show {
        visibility: visible;
      }
    }

    .checkbox {
      margin-top: ${({ theme }) => theme.spacingS};

      label {
        position: relative;
        font-size: 1.3rem;
        padding-left: ${({ theme }) => theme.spacingS};
        text-transform: uppercase;
        cursor: pointer;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .check {
          background: ${({ theme }) => theme.white};
        }
      }

      .check {
        position: absolute;
        top: 0;
        left: 0;
        width: 2rem;
        height: 2rem;
        border: 1px solid ${({ theme }) => theme.white};
      }
    }

    button {
      font-size: 1.8rem;
      text-transform: uppercase;
      margin-top: 4rem;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 1000px) {
    .content {
      flex-direction: column;
    }

    .contacts {
      order: 2;
      text-align: center;
      margin-top: ${({ theme }) => theme.spacingL};
    }
  }
`
